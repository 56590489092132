import React, { Component } from "react";
import {Helmet} from 'react-helmet'

class PageHelmet extends Component{
    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>RainClouds.org || {this.props.pageTitle}</title>
                    <meta name="description" content="RainClouds.org Disaster Response Technology for providing web-based systems and applications for assisting in efficient, effective responses to global disasters." />
                </Helmet>
            </React.Fragment>
        )
    }
}


export default PageHelmet;
