import React, { Component } from 'react'

import { FiChevronUp } from "react-icons/fi";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

import "mapbox-gl/dist/mapbox-gl.css"
import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css"

import ReactMapGL, { GeolocateControl, NavigationControl } from 'react-map-gl';
import DeckGL, { GeoJsonLayer } from "deck.gl";
import Geocoder from "react-map-gl-geocoder";

const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1Ijoibm5vbGFzY28iLCJhIjoiY2o3cjlhaGpxMmxuajJ3cnJmZDZneWppMSJ9.Z0JPQIHJHAwfpwI1hXNNuw';

// Initial viewport settings
const initialViewState = {
    longitude: -122.41669,
    latitude: 37.7853,
    zoom: 13,
    pitch: 0,
    bearing: 0
};

const geolocateStyle = {
    float: 'right',
    margin: '0px',
    padding: '0px'
};

// Data to be used by the LineLayer
const data = [{ sourcePosition: [-122.41669, 37.7853], targetPosition: [-122.41669, 37.781] }];

class MapBox extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            viewport: {
                width: '100%',
                height: '100%',
                latitude: 37.7577,
                longitude: -122.4376,
                zoom: 3.5,
                bearing: 0,
                pitch: 0
            },
            searchResultLayer: null
        }

        this.handleViewportChange = this.handleViewportChange.bind(this);
        this.handleGeocoderViewportChange = this.handleGeocoderViewportChange.bind(this);
        this.handleOnResult = this.handleOnResult.bind(this);
    }

    mapRef = React.createRef()

    handleViewportChange = viewport => {
        this.setState({
            viewport: { ...this.state.viewport, ...viewport }
        })
    }

    // if you are happy with Geocoder default settings, you can just use handleViewportChange directly
    handleGeocoderViewportChange = viewport => {
        console.log('geocoder viewport change');

        const geocoderDefaultOverrides = { transitionDuration: 1000 };
        
        return this.handleViewportChange({
            ...viewport,
            ...geocoderDefaultOverrides
        });
    };

    handleOnResult = event => {
        console.log('on result');

        this.setState({
            searchResultLayer: new GeoJsonLayer({
                id: "search-result",
                data: event.result.geometry,
                getFillColor: [255, 0, 0, 128],
                getRadius: 1000,
                pointRadiusMinPixels: 10,
                pointRadiusMaxPixels: 10
            })
        })
    }

    render() {
        const { viewport, searchResultLayer } = this.state;

        return (
            <>
                <PageHelmet pageTitle='Situation Map' />

                {/* Start Header Area  */}
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                {/* End Header Area  */}

                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Activity Map'} />
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper  */}
                <main className="page-wrapper">
                
                    {/* Start Contact Map  */}
                    <div className="rn-contact-map-wrapper ptb--120 bg_color--1">
                        <div className="container">
                            <div className="row">
                                {/* Start Single Map  */}
                                <div className="col-lg-12">
                                    <div className="rn-contact-map-area position-relative">
                                        <div style={{ height: '550px', width: '100%' }}>
                                            
                                        <ReactMapGL
                                                    ref={this.mapRef}
                                                    {...viewport}
                                                    mapStyle="mapbox://styles/mapbox/streets-v9"
                                                    width="100%"
                                                    height="90%"
                                                    onViewportChange={this.handleViewportChange}
                                                    mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
                                        >
                                            
                                                    <div style={{ position: 'absolute', width: 30, height: 30, right: 10, top: 10, zIndex: 1 }}>
                                                        <GeolocateControl
                                                            style={geolocateStyle}
                                                            positionOptions={{ enableHighAccuracy: true }}
                                                            trackUserLocation={true}
                                                        />
                                                    
                                                    </div>
                                                    <div style={{ position: 'absolute', right: 10, top: 100, zIndex: 1 }}>
                                                        <NavigationControl showCompass={false} />
                                                    </div>
                                                        <Geocoder
                                                mapRef={this.mapRef}
                                                onResult={this.handleOnResult}
                                                onViewportChange={this.handleGeocoderViewportChange}
                                                mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
                                                position='top-left'
                                                marker={true}
                                                    />
                                            
                                        </ReactMapGL>
                                        <DeckGL
                                            {...viewport}
                                            layers={[searchResultLayer]}
                                        />
                                        </div>
                                    </div>
                                </div>
                                {/* End Single Map  */}
                            </div>
                        </div>
                    </div>
                    {/* End Contact Map  */}
                </main>
                {/* End Page Wrapper  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                {/* Start Footer Area  */}
                <Footer />
                {/* End Footer Area  */}
            </>
        )
    }
}

export default MapBox;


